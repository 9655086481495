'use client';

import {Footer} from '@job-ish/blocks/components';
import {Button, Empty} from '@job-ish/ui/components';
import {IconError404} from '@tabler/icons-react';
import {useRouter} from 'next/navigation';

import {Header} from './_/components/header';

const NotFound = () => {
	const {push} = useRouter();

	return (
		<>
			<div className="flex min-h-dvh flex-col gap-20">
				<Header />
				<Empty
					className="grow"
					icon={IconError404}
					size="lg"
					subtitle="Sorry, the page you requested could not be found."
					title="Page not found"
				>
					<Button color="primary" onPress={() => push('/')}>
						Explore job-ish
					</Button>
				</Empty>
			</div>
			<Footer />
		</>
	);
};

export default NotFound;
